import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}>
        </Helmet>
      </HelmetProvider>
      <>
        <section className='relative pb-20'>
          <nav className='flex justify-between items-center py-8 px-4 xl:px-10'>
            <a className='text-lg font-semibold' href='/'>
              <img
                src='/images/logo.svg'
                alt='Logo'
                height="60px"
                width='auto'
              />
            </a>
              {/* <div className='block'>
                <a
                  className='inline-block py-3 px-8 text-sm leading-normal font-medium bg-red-50 hover:bg-red-100 text-red-500 rounded transition duration-200'
                  href='#'
                >
                  Contact Us
                </a>
              </div> */}
          </nav>
          <img
            className='hidden lg:block lg:absolute top-0 left-0 mt-32'
            src='zeus-assets/icons/dots/blue-dot-left-bars.svg'
            alt=''
          />
          <img
            className='hidden lg:block lg:absolute bottom-0 right-0 mt-20'
            src='zeus-assets/icons/dots/yellow-dot-right.svg'
            alt=''
          />
          <div className='relative container pt-12 px-4 mx-auto text-center'>
            <span className='text-blue-400 font-semibold'>
              Welcome to Asteri Software
            </span>
            <h2 className='mt-8 mb-8 lg:mb-12 text-4xl lg:text-6xl font-semibold'>
              We build digital solutions that improve the web.
            </h2>
            <p className='max-w-3xl mx-auto mb-8 lg:mb-12 text-xl text-gray-500'>
              Crafting innovative solutions to create online experiences that are easy to enjoy.
            </p>
            <a
              className='inline-block w-full md:w-auto mb-2 md:mb-0 px-8 py-4 mr-4 text-sm font-medium leading-normal bg-red-400 hover:bg-red-300 text-white rounded transition duration-200'
              href='#contact'
            >
              Get in Touch
            </a>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50'>
              {/* <div className='mt-auto'>
                <div className='pt-6'>
                  <a
                    className='block py-3 text-center text-sm leading-normal bg-red-50 hover:bg-red-100 text-red-300 font-semibold rounded transition duration-200'
                    href='#'
                  >
                    Contact Us
                  </a>
                </div>
                <p className='mt-6 mb-4 text-sm text-center text-gray-500'>
                  <span>© 2021 All rights reserved.</span>
                </p>
              </div> */}
          </div>
        </section>
        <section className='py-20'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -m-px'>
              <div className='w-full md:w-1/2 p-px'>
              <div className='text-center bg-white p-6 lg:py-12 lg:px-16 md:rounded-tr-lg hover:bg-white'>
                  <span className='flex items-center justify-center w-16 h-16 mb-12 mx-auto bg-yellow-300 rounded-full text-white'>
                    <svg
                      width={18}
                      height={21}
                      viewBox='0 0 18 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M8.98457 19.606C8.98457 19.606 16.6566 17.283 16.6566 10.879C16.6566 4.474 16.9346 3.974 16.3196 3.358C15.7036 2.742 9.99057 0.75 8.98457 0.75C7.97857 0.75 2.26557 2.742 1.65057 3.358C1.03457 3.974 1.31257 4.474 1.31257 10.879C1.31257 17.283 8.98457 19.606 8.98457 19.606Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M6.38599 9.87463L8.27799 11.7696L12.176 7.86963'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                  <h3 className='mb-4 text-2xl font-semibold font-heading'>
                    Website Development
                  </h3>
                  <p className='text-base text-gray-500'>
                    Building websites is what we do best.
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-px'>
              <div className='text-center bg-white p-6 lg:py-12 lg:px-16 md:rounded-tr-lg hover:bg-white'>
                  <span className='flex items-center justify-center w-16 h-16 mx-auto mb-12 bg-blue-400 rounded-full'>
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10 0.750122C15.108 0.750122 19.25 4.89112 19.25 10.0001C19.25 15.1081 15.108 19.2501 10 19.2501C4.891 19.2501 0.75 15.1081 0.75 10.0001C0.75 4.89112 4.891 0.750122 10 0.750122Z'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9.99512 6.20422V10.6232'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9.995 13.7961H10.005'
                        stroke='white'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                  <h3 className='mb-4 text-2xl font-semibold font-heading'>
                    Software Development
                  </h3>
                  <p className='text-base text-gray-500'>
                    Custom software development is our idea of fun.
                    </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-px'>
              <div className='text-center bg-white p-6 lg:py-12 lg:px-16 md:rounded-tr-lg hover:bg-white'>
                  <span className='flex-shrink-0 flex items-center justify-center w-16 h-16 mb-12 mx-auto bg-red-400 rounded-full text-white'>
                    <svg
                      width={18}
                      height={20}
                      viewBox='0 0 18 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.4235 7.4478V5.3008C13.4235 2.7878 11.3855 0.7498 8.87249 0.7498C6.35949 0.7388 4.31349 2.7668 4.30249 5.2808V5.3008V7.4478'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M12.6832 19.2496H5.04224C2.94824 19.2496 1.25024 17.5526 1.25024 15.4576V11.1686C1.25024 9.07359 2.94824 7.37659 5.04224 7.37659H12.6832C14.7772 7.37659 16.4752 9.07359 16.4752 11.1686V15.4576C16.4752 17.5526 14.7772 19.2496 12.6832 19.2496Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M8.86304 12.2028V14.4238'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                  <h3 className='mb-4 text-2xl font-semibold font-heading'>
                    Integrations
                  </h3>
                  <p className='text-base text-gray-500'>
                    Disparate data sources? No problem.
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-px'>
              <div className='text-center bg-white p-6 lg:py-12 lg:px-8 md:rounded-tr-lg hover:bg-white'>
                  <span className='flex items-center justify-center w-16 h-16 mb-12 mx-auto bg-orange-400 rounded-full text-white'>
                    <svg
                      width={22}
                      height={20}
                      viewBox='0 0 22 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M16.9026 6.8512L12.4593 10.4642C11.6198 11.1302 10.4387 11.1302 9.59919 10.4642L5.11841 6.8512'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                  <h3 className='mb-4 text-2xl font-semibold font-heading'>
                    Strategy &amp; Consulting
                  </h3>
                  <p className='text-base text-gray-500'>
                    Let us share our experience to help your project succeed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className='relative py-10'>
          <div className='container px-4 mx-auto'>
            <div className='flex items-center justify-around lg:-mx-4'>
              <div className='max-w-4xl mx-auto'>
                <div className='flex flex-wrap items-center lg:-mx-4'>
                  <div className='w-full lg:w-1/2 px-4 mb-8 lg:mb-0'>
                    <h2 className='mb-4 text-4xl font-semibold font-heading'>
                      Chris Moutsos
                    </h2>
                    <p className='mb-10 font-heading ml-2'>CEO &amp; Founder</p>
                    <p className='mb-10 text-xl text-gray-500'>
                      Chris has a passion for building products that improve the web. 
                      He has been developing websites, software, and integrations for over 10 years.
                    </p>
                  </div>
                  <div className='relative w-1/2 lg:w-1/2 lg:px-4'>
                    <div className='relative h-112 mb-10'>
                      <img
                        className='object-cover object-top rounded-xl'
                        src='/images/me.jpeg'
                        alt='Chris'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className='py-10' id="contact">
          <div className='container px-4 mx-auto'>
            <div className='max-w-2xl mx-auto mb-12 lg:mb-20 text-center'>
              <h2 className='mt-8 mb-10 text-3xl lg:text-4xl font-semibold font-heading'>
                    The best way to predict the future is to invent it.
              </h2>
              <p className='text-xl text-gray-500'>
                Let's work together to build something great.
              </p>
            </div>
            <div className='flex flex-wrap lg:-mx-4'>
              {/* <div className='w-full lg:w-1/3 px-4 mb-4 lg:mb-0'>
                <div className='py-12 h-full border text-center rounded-xl'>
                  <h3 className='mb-6 text-2xl font-semibold font-heading'>
                    Address
                  </h3>
                  <p className='text-gray-500'>1686 Geraldine Lane</p>
                  <p className='text-gray-500'>New York, NY 10013</p>
                </div>
              </div> */}
              <div className='w-full lg:w-1/3 mx-auto px-4 mb-4 lg:mb-0'>
                <div className='py-12 h-full border text-center rounded-xl'>
                  <h3 className='mb-6 text-2xl font-semibold font-heading'>
                    Contact
                  </h3>
                  <p className='text-gray-500'>hello@asterisoftware.com</p>
                </div>
              </div>
              {/* <div className='w-full lg:w-1/3 px-4 lg:mb-0'>
                <div className='py-12 h-full border text-center rounded-xl'>
                  <h3 className='mb-7 text-2xl font-semibold font-heading'>
                    Socials
                  </h3>
                  <div className='flex flex-wrap items-end justify-center'>
                    <a
                      className='inline-flex items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-50'
                      href='#'
                    >
                      <svg
                        width={7}
                        height={12}
                        viewBox='0 0 7 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M4.08986 11.8182V6.51068H5.90543L6.17782 4.44164H4.08986V3.12086C4.08986 2.52201 4.2587 2.1139 5.13522 2.1139L6.25131 2.11345V0.26283C6.0583 0.238228 5.39575 0.181824 4.62462 0.181824C3.01437 0.181824 1.91196 1.14588 1.91196 2.91594V4.44164H0.0908813V6.51068H1.91196V11.8182H4.08986Z'
                          fill='#758A99'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-50'
                      href='#'
                    >
                      <svg
                        width={13}
                        height={11}
                        viewBox='0 0 13 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M12.5454 2.09731C12.0904 2.29895 11.6021 2.43569 11.0891 2.49674C11.6129 2.18307 12.0139 1.68553 12.2039 1.0945C11.7126 1.38499 11.1702 1.59592 10.5923 1.71026C10.1296 1.21658 9.47132 0.909088 8.74122 0.909088C7.34053 0.909088 6.20483 2.04479 6.20483 3.4447C6.20483 3.64325 6.22724 3.83717 6.27051 4.0226C4.16291 3.91675 2.29405 2.90699 1.04324 1.37262C0.824591 1.74656 0.700208 2.18228 0.700208 2.64739C0.700208 3.52737 1.14831 4.30382 1.82819 4.75808C1.41253 4.74418 1.0216 4.62984 0.679359 4.43978V4.47145C0.679359 5.69986 1.55392 6.72507 2.71356 6.9584C2.5011 7.01557 2.27706 7.04725 2.04528 7.04725C1.8815 7.04725 1.72312 7.03103 1.56782 7.00012C1.89075 8.00834 2.82714 8.74151 3.93657 8.76161C3.06895 9.44149 1.97498 9.84555 0.786753 9.84555C0.582026 9.84555 0.380369 9.83319 0.181824 9.81079C1.30439 10.5316 2.6371 10.9519 4.06946 10.9519C8.73508 10.9519 11.2854 7.08743 11.2854 3.73598L11.2769 3.40763C11.7752 3.05222 12.2063 2.60567 12.5454 2.09731Z'
                          fill='#758A99'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-50'
                      href='#'
                    >
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M4.06713 0.454544H9.9328C11.9249 0.454544 13.5456 2.07521 13.5455 4.06716V9.93284C13.5455 11.9248 11.9249 13.5455 9.9328 13.5455H4.06713C2.07518 13.5455 0.45459 11.9249 0.45459 9.93284V4.06716C0.45459 2.07521 2.07518 0.454544 4.06713 0.454544ZM9.9329 12.384C11.2845 12.384 12.3841 11.2844 12.3841 9.93283H12.384V4.06716C12.384 2.71565 11.2844 1.61603 9.93282 1.61603H4.06715C2.71564 1.61603 1.61609 2.71565 1.61609 4.06716V9.93283C1.61609 11.2844 2.71564 12.384 4.06715 12.384H9.9329ZM3.57148 7.00007C3.57148 5.10948 5.10951 3.57142 7.00005 3.57142C8.8906 3.57142 10.4286 5.10948 10.4286 7.00007C10.4286 8.89058 8.8906 10.4286 7.00005 10.4286C5.10951 10.4286 3.57148 8.89058 3.57148 7.00007ZM4.75203 6.99999C4.75203 8.23952 5.76054 9.2479 7.00004 9.2479C8.23955 9.2479 9.24806 8.23952 9.24806 6.99999C9.24806 5.76038 8.23963 4.75192 7.00004 4.75192C5.76046 4.75192 4.75203 5.76038 4.75203 6.99999Z'
                          fill='#758A99'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-50'
                      href='#'
                    >
                      <svg
                        width={12}
                        height={12}
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.8 0H1.2C0.54 0 0 0.54 0 1.2V10.8C0 11.46 0.54 12 1.2 12H10.8C11.46 12 12 11.46 12 10.8V1.2C12 0.54 11.46 0 10.8 0ZM3.6 10.2H1.8V4.8H3.6V10.2ZM2.7 3.78C2.1 3.78 1.62 3.3 1.62 2.7C1.62 2.1 2.1 1.62 2.7 1.62C3.3 1.62 3.78 2.1 3.78 2.7C3.78 3.3 3.3 3.78 2.7 3.78ZM10.2 10.2H8.4V7.02C8.4 6.54002 7.98 6.12 7.5 6.12C7.02 6.12 6.6 6.54002 6.6 7.02V10.2H4.8V4.8H6.6V5.52C6.9 5.04 7.56 4.68 8.1 4.68C9.24 4.68 10.2 5.64 10.2 6.78V10.2Z'
                          fill='#758A99'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-50'
                      href='#'
                    >
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 2C4.685 2 2 4.75474 2 8.15226C2 10.871 3.719 13.1765 6.1025 13.9894C6.4025 14.0473 6.5125 13.8569 6.5125 13.6936C6.5125 13.5475 6.5075 13.1604 6.505 12.6475C4.836 13.0184 4.484 11.8221 4.484 11.8221C4.211 11.112 3.8165 10.9221 3.8165 10.9221C3.273 10.5407 3.8585 10.5486 3.8585 10.5486C4.461 10.5913 4.7775 11.1825 4.7775 11.1825C5.3125 12.1232 6.182 11.8513 6.525 11.6943C6.579 11.2962 6.7335 11.0254 6.905 10.8715C5.5725 10.7176 4.172 10.1886 4.172 7.83139C4.172 7.15992 4.4045 6.61106 4.7895 6.18063C4.722 6.02516 4.5195 5.3996 4.842 4.55231C4.842 4.55231 5.3445 4.38744 6.492 5.18308C6.972 5.04639 7.482 4.97856 7.992 4.97543C8.502 4.97856 9.012 5.04639 9.492 5.18308C10.632 4.38744 11.1345 4.55231 11.1345 4.55231C11.457 5.3996 11.2545 6.02516 11.1945 6.18063C11.577 6.61106 11.8095 7.15992 11.8095 7.83139C11.8095 10.1948 10.407 10.715 9.072 10.8663C9.282 11.051 9.477 11.4282 9.477 12.0047C9.477 12.828 9.4695 13.4896 9.4695 13.6894C9.4695 13.8506 9.5745 14.0431 9.882 13.9816C12.2825 13.1739 14 10.8668 14 8.15226C14 4.75474 11.3135 2 8 2Z'
                          fill='#758A99'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
            </div>*/}
            </div>
          </div>
        </section>
        <section className='py-20'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap justify-between items-center lg:-mx-4'>
              <div className='w-full lg:w-2/6 px-4'>
                <a
                  className='inline-block mb-6 text-gray-900 text-lg font-semibold'
                  href='/'
                >
                    <img
                      src='/images/logo.svg'
                      alt='Logo'
                      height="60px"
                      width='auto'
                  />
                </a>
                <p className='hidden lg:block text-sm text-gray-500'>
                All rights reserved © Asteri Software, LLC
                </p>
              </div>
              <div className='w-full lg:w-4/6 px-4'>
                <div className='flex flex-wrap items-center justify-end'>
                  <a
                    className='inline-block mr-auto lg:mr-0 py-4 px-8 text-sm text-white font-medium leafing-normal bg-red-400 hover:bg-red-300 rounded'
                    href='#contact'
                  >
                    Get in Touch
                  </a>
                </div>
                <p className='mt-6 lg:hidden text-sm text-gray-500'>
                  All rights reserved © Asteri Software, LLC
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

